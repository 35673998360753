//Library
import {memo, useCallback, useEffect, useMemo, useState} from 'react'
import {AxiosError} from 'axios'
import {Form} from 'react-bootstrap'
import {notEmpty, useField, useForm} from '@shopify/react-form'

//Components
import TextField from 'components/TextField'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import {createLevel} from '../store/channelSetting.store.reduce.ts'

//Helpers
import helpers from 'helpers'

//Interface
import {TypeDataLevel} from '../interface'
import __ from "languages/index";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";
import { Button } from '@mui/material'

//Interface

const AddLevel = ({
  setIsShowAdd,
  isRefresh,
  setIsRefresh,
  listLevel
}: {
  setIsShowAdd: Function
  isRefresh: boolean
  setIsRefresh: Function
  listLevel: TypeDataLevel[]
}) => {
  const dispatch = useAppDispatch()

  const channel_data = useAppSelector(state => state.user.channel_data)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const toast = useToast()

  const highestLevel = useMemo(() => {
    if (helpers.isEmpty(listLevel)) return null
    return listLevel[listLevel?.length - 1]
  }, [listLevel])

  const validateLevelNumber = useCallback(
    (level_number: string) => {
      if (helpers.isEmpty(listLevel)) return
      const checkLevel = listLevel.find(
        (item: TypeDataLevel) => Number(item.level_number) === Number(level_number)
      )
      return !helpers.isEmpty(checkLevel)
    },
    [listLevel]
  )

  const { fields, submit, dirty, submitting } = useForm({
    fields: {
      level_number: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__("channel_setting_fill_level")}`),
          inputVal => {
            if (!inputVal) {
              return `${__("channel_setting_fill_level")}`
            }
            if (Number(inputVal) > 50) {
              return `${__("channel_setting_level_max")}`
            }
            if (validateLevelNumber(inputVal)) {
              return `${__("channel_setting_not_duplicate_level")}`
            }
          }
        ]
      }),
      title: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__("channel_setting_fill_name_level")}`),
          inputVal => {
            if (!inputVal) {
              return `${__("channel_setting_fill_name_level")}`
            }
          }
        ]
      }),
      level_point: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__("channel_setting_fill_point_level")}`),
          inputVal => {
            if (!inputVal) {
              return `${__("channel_setting_fill_point_level")}`
            }
            if (Number(inputVal) <= Number(highestLevel?.level_point)) {
              return `${__("channel_setting_fill_point_level_note")}`
            }
          }
        ]
      })
    },
    async onSubmit(values) {
      try {
        const formData = {
          channel_id: channel_data?._id,
          title: values.title.trim(),
          level_point: `${values.level_point}`,
          level_number: `${values.level_number}`
        }
        setIsLoading(true)
        dispatch(createLevel(formData))
          .unwrap()
          .then(() => {
            setIsLoading(false)
            setIsRefresh(!isRefresh)
            setIsShowAdd(false)
            toast.show({
              content: `${__("channel_setting_create_level_success")}`,
              type: EnumTypeToast.Success
            })
          })
          .catch((error: AxiosError) => {
            console.log(`createLevel_${error}`)
            setIsLoading(false)
            toast.show({
              content: `${__("channel_setting_create_level_failed")}`,
              type: EnumTypeToast.Error
            })
          })
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  useEffect(() => {
    if (helpers.isEmpty(highestLevel)) return
    fields.level_number.onChange(`${Number(highestLevel?.level_number) + 1}`)
  }, [highestLevel])

  return (
    <div id="addLevel" className="addLevel_container">
      <div className="channelSettingLevel_header">
        <h4 className="channelSettingLevel_header_title">{__("channel_setting_add_level")}</h4>
      </div>
      <Form onSubmit={submit} className="addLevel_form_wrapper">
        <div className="addLevel_form">
          <div className="addLevel_form_item">
            <label>{__("channel_setting_level")}</label>
            <TextField
              {...fields.level_number}
              autoFocus
              disabled={true}
              onChange={(value: string) =>
                fields.level_number.onChange(helpers.parseNumeric(value))
              }
              placeholder={__("channel_setting_level")}
            />
          </div>
          <div className="addLevel_form_item">
            <label>{__("channel_setting_level_name")}</label>
            <TextField
              {...fields.title}
              onChange={(value: string) => fields.title.onChange(value)}
              placeholder={__("channel_setting_level_name")}
            />
          </div>
          <div className="addLevel_form_item">
            <label>{__("channel_setting_level_point")}</label>
            <TextField
              {...fields.level_point}
              onChange={(value: string) => fields.level_point.onChange(helpers.parseNumeric(value))}
              placeholder={__("channel_setting_level_point")}
            />
          </div>
        </div>
        <div className="addLevel_form_button">
          <Button sx={{textTransform: 'none',color: 'error.main'}} onClick={() => setIsShowAdd('')} variant="text">
            {__("btn_cancel")}
          </Button>
          <Button
            sx={{textTransform: 'none'}}
            disabled={isLoading || !dirty || submitting}
            onClick={submit}
          >
            {isLoading ? `${__("btn_processing")}` : `${__("btn_save")}`}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default memo(AddLevel)
