//Library
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { createEntitySlice, serializeAxiosError, IQueryParams } from 'config/reducer.utils'

//Helpers
import helpers from 'helpers'
import { TypedCustomerAddressBook, TypedSummarize } from '../interface'
import { getSummarizeOfProduct } from '../service/product.service'
import { TypedProductCategory } from 'interfaces/shop.interface'

export const initialState: {
  summarize: TypedSummarize
} = {
  summarize: {
    product_review_point: 0,
    product_review_count: 0,
    review_sumary: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0
    }
  }
}

export const getCategoryOfShop = createAsyncThunk(
  'shop/get_category',
  async (_params: IQueryParams, { rejectWithValue }) => {
    try {
      const response = await axios.get<any[]>(`v2/product_category`, { params: _params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getBannerOfShop = createAsyncThunk(
  'shop/get_banner',
  async (_params, { rejectWithValue }) => {
    try {
      const response = await axios.get<any>(`v2/banner`)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const createBannerOfShop = createAsyncThunk(
  'shop/create_banner',
  async (
    body: {
      banner_image_url: string
      banner_title: string
      banner_type: string | 'big' | 'medium'
    },
    { rejectWithValue }
  ) => {
    try {
      return await axios.post<any>(`v2/banner`, body)
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const createProductOfShop = createAsyncThunk(
  'shop/create_product',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>(`v2/product`, body)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getCollectionOfShop = createAsyncThunk(
  'shop/get_collection',
  async (_params, { rejectWithValue }) => {
    try {
      const response = await axios.get<any>(`v2/product_collection`)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getProductOfShop = createAsyncThunk(
  'shop/get_product',
  async (_params: IQueryParams, { rejectWithValue }) => {
    try {
      const response = await axios.get<any>(`v2/product`, { params: _params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getProductCollectionOfShop = createAsyncThunk(
  'shop/get_product_collection',
  async (_params: IQueryParams, { rejectWithValue }) => {
    try {
      const response = await axios.get<any>(`v2/product_collection`, { params: _params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const deleteBannerOfShop = createAsyncThunk(
  'shop/delete_banner',
  async (banner_id: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete<any>(`v2/banner/${banner_id}`)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const updateBannerOfShop = createAsyncThunk(
  'shop/delete_banner',
  async ({ banner_id, formData }: any, { rejectWithValue }) => {
    try {
      const response = await axios.put<any>(`v2/banner/${banner_id}`, formData)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getCustomerAddressBook = createAsyncThunk(
  'shop/my_customer',
  async () => {
    const response = await axios.get<TypedCustomerAddressBook[]>(`v2/my_customer_address_book`)
    return response
  },
  { serializeError: serializeAxiosError }
)

export const getSummarize = createAsyncThunk(
  'shop/summarize',
  async (productId: string, { rejectWithValue }) => {
    try {
      const response = await getSummarizeOfProduct(productId)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }, { serializeError: serializeAxiosError });


export const SHOP_REDUCER = createEntitySlice({
  name: 'shop',
  initialState: initialState,
  reducers: {
    clearError: () => initialState
  },
  extraReducers(builder) {
    builder
      .addCase(getSummarize.fulfilled, (state, action) => {
        state.summarize = action.payload.data
      })
  }
})

export const { clearError } = SHOP_REDUCER.actions
// Reducer
export default SHOP_REDUCER.reducer
