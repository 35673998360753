import { SvgIcon, SvgIconProps } from '@mui/material'

const ArrowBarLeftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4998 3.13477C21.0521 3.13477 21.4998 3.58248 21.4998 4.13477V20.1348C21.4998 20.687 21.0521 21.1348 20.4998 21.1348C19.9476 21.1348 19.4998 20.687 19.4998 20.1348V4.13477C19.4998 3.58248 19.9476 3.13477 20.4998 3.13477ZM9.20696 7.42766C9.59748 7.81818 9.59748 8.45135 9.20696 8.84187L6.91406 11.1348H14.4998C15.0521 11.1348 15.4998 11.5825 15.4998 12.1348C15.4998 12.6871 15.0521 13.1348 14.4998 13.1348H6.91406L9.20696 15.4277C9.59748 15.8182 9.59748 16.4513 9.20696 16.8419C8.81643 17.2324 8.18327 17.2324 7.79274 16.8419L4.49985 13.549C3.7188 12.7679 3.7188 11.5016 4.49985 10.7206L7.79274 7.42766C8.18327 7.03713 8.81643 7.03713 9.20696 7.42766Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default ArrowBarLeftIcon
