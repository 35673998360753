//Library
import { Suspense, lazy, useCallback, useEffect, useLayoutEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { createPortal } from 'react-dom'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'

//Components
import ScrollToTop from 'components/ScrollToTop'
import EventTrigger from 'components/eventTrigger'

const Theme404 = lazy(() => import('./layout/404'))
const AppToast = lazy(() => import('components/AppToast'))
const LogoutModal = lazy(() => import('components/LogoutModal'))
const MainLayout = lazy(() => import('layouts/MainLayout'))
const ChannelSettingLayout = lazy(() => import('layouts/ChannelSettingLayout'))
const ChannelSettingsInformation = lazy(() => import('entities/channel-settings/information'))
const PostCategory = lazy(() => import('entities/channel-settings/post-category'))
const PointConfigurations = lazy(() => import('entities/channel-settings/point-configurations'))
const ChannelSettingsAdvance = lazy(() => import('entities/channel-settings/advance'))
const InviteUser = lazy(() => import('entities/channel-settings/invite-user'))
const Level = lazy(() => import('entities/channel-settings/level'))
const CreditConfigurations = lazy(() => import('entities/channel-settings/credit-configurations'))
const EmailNotification = lazy(() => import('entities/channel-settings/email-notification'))
const ChannelCreate = lazy(() => import('entities/channel/channel.create'))

//Store
import getStore, { useAppDispatch, useAppSelector } from 'config/store'
import { public_route, separate_route as SeparateRoute } from './config/router-define'
import {
  checkCreatedChannel,
  getCurrentUserData,
  getDefaultChannel,
  getLevelsChannel,
  getUserPermission,
  setLoadingDoneFirstTime
} from 'store/user.store.reducer'
import { clearError } from 'store/global.warning.store.reducer'

//Helper
import helpers from 'helpers'

//Constants
import { socket } from 'config/socket.io'
import { AUTH_SCREEN, GAMIFA_PRIMARY_COLOR } from 'constant/index'

//Css
// Styles must use direct files imports
import { getThemeByMode } from 'styles/themes'
import { lightPalette } from './styles/themes/palettes/defaultPalete'
import { colorGenerator } from './styles/utils/colorPaletteGenerator'
import 'yet-another-react-lightbox/styles.css'
import { bindActionCreators } from '@reduxjs/toolkit'
import { redirectTo } from 'store/auth.store.reducer'
import RedirectToUrl from 'components/RedirectTo'
import { ROUTER } from 'constant/router'

const baseHref = document.querySelector('base')?.getAttribute('href')?.replace(/\/$/, '')

export default function App() {
  const channel_data = useAppSelector(state => state.user.channel_data)
  // const channel = useAppSelector(channelInfo)
  const is_user_logged_in = useAppSelector(state => state.user.is_user_logged_in)
  const errorMessage = useAppSelector(state => state.global_notification.errorMessage)
  const dispatch = useAppDispatch()

  const store = getStore()

  /**
   * If API header return 401 or 403, call login now!
   */
  const actions = bindActionCreators({ redirectTo }, store.dispatch)
  /**
   * If API header return 401 or 403, call login now!
   */

  const getDataForDisplay = useCallback(async () => {
    try {
      dispatch(getDefaultChannel())
      dispatch(getUserPermission()) // new way to get permission

      if (!!localStorage.getItem('session')) {
        dispatch(getCurrentUserData())
        dispatch(checkCreatedChannel())
      } else {
        dispatch(setLoadingDoneFirstTime())
      }
    } catch (e) {}
  }, [])

  useLayoutEffect(() => {
    getDataForDisplay()
  }, [])

  useEffect(() => {
    if (!channel_data?._id) return
    dispatch(getLevelsChannel({ channel_id: channel_data?._id }))
  }, [channel_data?._id])

  useEffect(() => {
    setTimeout(() => {
      socket.io.opts.extraHeaders = {
        'X-Authorization': localStorage.getItem('session')
      }
      socket.connect()
    }, 2000)

    return () => {
      socket.disconnect()
    }
  }, [is_user_logged_in])

  useEffect(() => {
    if (channel_data?.country) {
      localStorage.setItem(
        'languageChannel',
        ['VN', 'HK'].includes(channel_data?.country?.toUpperCase()) ? 'vi' : 'en'
      )
    }
  }, [channel_data?.country])

  useEffect(() => {
    if (
      channel_data?.public_status &&
      channel_data?.public_status === 'private' &&
      !localStorage.getItem('session') &&
      !AUTH_SCREEN.some(item => window.location.pathname.includes(item))
    ) {
      actions.redirectTo(ROUTER.AUTH.LOGIN)
    }
  }, [
    channel_data?.public_status,
    channel_data?.facebook_login_client_id,
    channel_data?.google_login_client_id,
    is_user_logged_in
  ])

  useEffect(() => {
    const { hash } = window.location
    if (hash) {
      const hashURL = String(hash || ' ').replace('#', '')
      let _url = new URLSearchParams(hashURL)
      const user_referrer = _url.get('user_referrer') || ''
      if (user_referrer) {
        localStorage.setItem('user_referrer', user_referrer)
      }
    }
  }, [window.location])

  useEffect(() => {
    if (!errorMessage) return
    dispatch(clearError())
  }, [errorMessage])

  useEffect(() => {
    if (!channel_data?.primary_color) return
    localStorage.setItem(
      'primary_color',
      helpers.replaceLastOccurrencesColor(channel_data?.primary_color, 'ff', '')
    )
  }, [channel_data?.primary_color])

  const chanelColor = channel_data?.primary_color
    ? helpers.replaceLastOccurrencesColor(channel_data?.primary_color, 'ff', '')
    : GAMIFA_PRIMARY_COLOR
  const paletteGenerated = {
    ...lightPalette,
    ...colorGenerator(chanelColor, 'primary')
  }

  const currentTheme = createTheme({
    ...getThemeByMode('light', {
      lightPalette: paletteGenerated
    })
  })

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <BrowserRouter basename={process.env.PUBLIC_URL || baseHref}>
        <ScrollToTop />
        <RedirectToUrl />
        <EventTrigger />
        <Suspense fallback={<></>}>
          <>{createPortal(<AppToast />, document.body)}</>

          {/* Logout Modal */}
          <LogoutModal />

          {/* <BlockWebOnMobileModal channelData={channel_data} showModal={helpers.isMobile()} /> */}

          <Routes>
            <Route element={<MainLayout />}>
              {public_route.map((Routex, index) => (
                <Route key={`public_${index}`} path={Routex.path} element={<Routex.main />} />
              ))}
              <Route path="/channel-settings" element={<ChannelSettingLayout />}>
                <Route path="/channel-settings/invite-user" element={<InviteUser />} />
                <Route
                  path="/channel-settings/information"
                  element={<ChannelSettingsInformation />}
                />
                <Route path="/channel-settings/post-categories" element={<PostCategory />} />
                <Route path="/channel-settings/gamifications" element={<Level />} />
                <Route
                  path="/channel-settings/credit-configurations"
                  element={<CreditConfigurations />}
                />
                <Route
                  path="/channel-settings/point-configurations"
                  element={<PointConfigurations />}
                />
                <Route
                  path="/channel-settings/email-notification"
                  element={<EmailNotification />}
                />
                <Route path="/channel-settings/advance" element={<ChannelSettingsAdvance />} />
              </Route>
            </Route>

            <Route path="/temp/template" element={<ChannelCreate />} />

            {SeparateRoute.map((Routex, index) => (
              <Route key={index} path={Routex.path} element={<Routex.main />} />
            ))}

            <Route key={99999} path="*" element={<Theme404 includeHeader />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  )
}
