import React, {forwardRef, useCallback, useImperativeHandle, useRef, useState} from "react";
import { Modal, ModalProps} from "react-bootstrap";
import __ from "languages/index";
import { Button } from "@mui/material";


export const ConfirmModal = forwardRef((propsModal: ModalProps, ref) => {
  const [show, setShow] = useState(false);

  const refDataModal = useRef({
    title: "",
    description: "",
    cancelText: "",
    confirmText: "",
    onCancel: () => {
    },
    onConfirm: () => {
    },
  })

  useImperativeHandle(ref, () => ({
    show: ({title, description, cancelText = `${__("btn_cancel")}`, confirmText = `${__("btn_confirm")}`, onCancel = ()=>{}, onConfirm=()=>{}}) => {
      refDataModal.current = {title, description, cancelText, confirmText, onCancel, onConfirm}
      setShow(true)
    },
  }),[])

  const handleClose = useCallback(() => setShow(false), []);

  const onCancel = useCallback(() => {
    handleClose();
    refDataModal.current?.onCancel?.()
  }, [])

  const onConfirm = useCallback(() => {
    handleClose();
    refDataModal.current?.onConfirm?.()
  }, [])

  return (
    <Modal {...propsModal} show={show} onHide={handleClose}>
      <Modal.Header><Modal.Title>{refDataModal.current?.title}</Modal.Title></Modal.Header>
      <Modal.Body>{refDataModal.current?.description}</Modal.Body>
      <Modal.Footer>
        <Button variant="text" sx={{textTransform: 'none'}} onClick={onCancel}>
          {refDataModal.current?.cancelText}
        </Button>
        <Button sx={{textTransform: 'none'}} onClick={onConfirm}>{refDataModal.current?.confirmText}</Button>
      </Modal.Footer>
    </Modal>
  );
});
