//Library
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { createEntitySlice, serializeAxiosError, IQueryParams } from 'config/reducer.utils'

//Helpers
import helpers from 'helpers'
import { TypedReferralCode } from '../interface'

export const initialState = {}

export const getReferralCode = createAsyncThunk(
  'get/referral_code',
  async (_params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(_params)
      const response = await axios.get<TypedReferralCode[]>(`/v2/invitation_code`, {
        params: _params
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const createReferralCode = createAsyncThunk(
  'create/referral_code',
  async (
    body: { invitation_title: string; invitation_code: string; quantity: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<any>(`/v2/invitation_code`, body)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getUserReferralAnalytics = createAsyncThunk(
  'get/referral',
  async (_params, { rejectWithValue }) => {
    try {
      const response = await axios.get<TypedReferralCode[]>(`/v2/user_referrer/analytics`)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const REFERRAL_REDUCER = createEntitySlice({
  name: 'referral',
  initialState: initialState,
  reducers: {
    clearError: () => initialState
  },
  extraReducers(builder) {}
})

export const { clearError } = REFERRAL_REDUCER.actions
// Reducer
export default REFERRAL_REDUCER.reducer
