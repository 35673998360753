import axios from 'axios'
import { IQueryParams } from 'config/reducer.utils'
import { IAddToCart } from 'entities/shop/store/cart/cart.action'
export const list = (params: IQueryParams) => {
  return axios.get('v2/cart_items', { params: params })
}
export const getAddressOfShop = (params: IQueryParams) => {
  return axios.get('v2/user_address_book', { params: params })
}
export const getLogisticOfShop = () => {
  return axios.get('v2/logistic_service')
}
export const updateDefaultAddressOfShop = (body: any) => {
  return axios.patch(`v2/user_address_book/${body.id}`, body)
}
export const deleteAddressOfShop = (id: string) => {
  return axios.delete(`v2/user_address_book/${id}`)
}
export const createAddressOfShop = (body: any) => {
  return axios.post(`v2/user_address_book`, body)
}
export const orderOfShop = body => {
  return axios.post(`v2/orders`, body)
}
export const removeCartItemAfterOrderOfShop = (id: string) => {
  return axios.delete(`v2/cart_items/${id}`)
}
export const updateQuantityService = (id: string, body: { item_quantity: number }) => {
  return axios.patch(`v2/cart_items/${id}`, body)
}
export const add = (item: IAddToCart['payload']) => {
  const payload = Boolean(item?.product?.product_parent) ? {
    product_id: item.product.product_parent,
    item_quantity: item.item_quantity,
    product_variant_id: item.product.product_id
  }:{
    product_id: item.product.product_id,
    item_quantity: item.item_quantity,
  }
  return axios.post('v2/cart_items', payload)
}
export const removeItemById = (itemId: string) => {
  return axios.delete(`v2/cart_items/${itemId}`)
}
export default { list, add }
