//Library
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IQueryParams, createEntitySlice, serializeAxiosError } from 'config/reducer.utils'

//helpers
import helpers from 'helpers'
import { TypedBusinessLevel } from 'layout/BusinessCooperationLevel'

export const initialState = {redirectToUrl: ''}

/**
 * @deprecated remove in a near future ...
 */
export const getListSystem = createAsyncThunk(
  'auth/get_list_system',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/config/list/system`)
      return response?.data?.config?.option_content.filter(
        (e: any) => e.key === 'is_enable_validate_phone'
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const userLoggedOut = createAsyncThunk('user/logout', async () => {
  return await axios.get<any>(`v2/logout`)
})

export const loginWithPassword = createAsyncThunk(
  'user/login_with_password',
  async (entities: any) => {
    return await axios.post<any>(`v2/login`, entities)
  }
)

export const loginWithFacebook = createAsyncThunk(
  'user/login_with_facebook',
  async (body: any, { rejectWithValue }) => {
    try {
      return await axios.post<any>(`/v2/login/facebook`, body)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const loginWithGoogle = createAsyncThunk(
  'user/login_with_google',
  async (body: any, { rejectWithValue }) => {
    try {
      return await axios.post<any>(`/v2/login/google`, body)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const registerAccount = createAsyncThunk('auth/register_account', async (body: any) => {
  const response = await axios.post<unknown>(`/v2/register`, body)
  return response
})

/**
 * For user login SSO
 * Deprecated
 */
export const updateInformation = createAsyncThunk('auth/update_information', async (body: any) => {
  const response = await axios.post<unknown>(`/v2/user/update-social-info`, body)
  return response
})

export const checkInviteCodeReferral = createAsyncThunk(
  'auth/check_invite_code',
  async (inviteCode: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<unknown>(`/v2/invitation_code/check/${inviteCode}`)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getBusinessLevel = createAsyncThunk(
  'auth/get_business_level',
  async (_params, { rejectWithValue }) => {
    try {
      const response = await axios.get<TypedBusinessLevel[]>(`/v2/user_group`)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const updatePaymentWitness = createAsyncThunk(
  'auth/update_payment_witness',
  async ({group_id, body}: {group_id: string,body: {payment_witness: string}}, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/v2/user_group/join_request/${group_id}`, body)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const joinRequestUserGroup = createAsyncThunk(
  'auth/get_business_level',
  async (group_id: string, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/v2/user_group/join_request/${group_id}`)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export async function paymentMember(data: {
  user_to_group_id: string
  callback_url: string
}): Promise<any> {
  try {
    const responseNumOfChatRoom = await axios.post(`/v2/payment/member`, data)
    return responseNumOfChatRoom?.data
  } catch (error) {
    console.log(error, 'error paymentMember')
    return Promise.reject(error)
  }
}

export const AUTH_REDUCER = createEntitySlice({
  name: 'auth',
  initialState: initialState as any,
  reducers: {
    redirectTo(state, action){
      state.redirectToUrl = action.payload
     }
  },
  extraReducers(builder) {}
})

export const {redirectTo} = AUTH_REDUCER.actions

export default AUTH_REDUCER.reducer
