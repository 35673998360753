import { SvgIcon, SvgIconProps } from '@mui/material'

const ShopSidebarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="currentColor"
      >
        <path d="M33.3525 13.1641H2.64453V32.2526H33.3525V13.1641Z" fill="#A1CAF4" />
        <path
          d="M3.58832 0.726562H32.4182C32.668 0.726563 32.9077 0.846734 33.0843 1.06064C33.261 1.27455 33.3603 1.56467 33.3603 1.86718V8.41046H2.65234V1.86718C2.65234 1.56595 2.75075 1.27695 2.92611 1.06326C3.10146 0.849564 3.33952 0.728526 3.58832 0.726562Z"
          fill="#FFC21A"
        />
        <path
          d="M2.10719 1.85352H4.56383V13.166H0.726562V3.52503C0.726563 3.08171 0.872021 2.65656 1.13094 2.34309C1.38986 2.02962 1.74103 1.85352 2.10719 1.85352Z"
          fill="#FF3C59"
        />
        <path
          d="M31.4336 1.85352H33.8902C34.0715 1.85352 34.2511 1.89675 34.4186 1.98075C34.5861 2.06475 34.7383 2.18788 34.8665 2.34309C34.9947 2.4983 35.0964 2.68257 35.1658 2.88537C35.2351 3.08816 35.2709 3.30552 35.2709 3.52503V13.166H31.4336V1.85352Z"
          fill="#FF3C59"
        />
        <path d="M8.4049 1.85352H4.56641V13.1674H8.4049V1.85352Z" fill="#E7EFFF" />
        <path d="M12.2447 1.85352H8.40625V13.1674H12.2447V1.85352Z" fill="#FF3C59" />
        <path d="M16.0807 1.85352H12.2422V13.1674H16.0807V1.85352Z" fill="#E7EFFF" />
        <path d="M19.9166 1.85352H16.0781V13.1674H19.9166V1.85352Z" fill="#FF3C59" />
        <path d="M23.7565 1.85352H19.918V13.1674H23.7565V1.85352Z" fill="#E7EFFF" />
        <path d="M27.5963 1.85352H23.7578V13.1674H27.5963V1.85352Z" fill="#FF3C59" />
        <path d="M31.4322 1.85352H27.5938V13.1674H31.4322V1.85352Z" fill="#E7EFFF" />
        <path
          d="M2.64519 15.989C2.13634 15.989 1.64833 15.7443 1.28852 15.3087C0.928706 14.8731 0.726562 14.2822 0.726562 13.6662V12.9062H4.56505V13.6662C4.56505 13.9713 4.51539 14.2735 4.41889 14.5554C4.32239 14.8373 4.18096 15.0935 4.00267 15.3092C3.82437 15.5249 3.61272 15.696 3.3798 15.8126C3.14688 15.9293 2.89726 15.9892 2.64519 15.989Z"
          fill="#E42D4F"
        />
        <path
          d="M31.4336 12.9043H35.2721V13.6642C35.2721 14.2803 35.0699 14.8711 34.7101 15.3067C34.3503 15.7423 33.8623 15.9871 33.3535 15.9871C33.1014 15.9873 32.8518 15.9273 32.6188 15.8107C32.3859 15.694 32.1743 15.523 31.996 15.3073C31.8177 15.0915 31.6763 14.8354 31.5798 14.5535C31.4833 14.2716 31.4336 13.9694 31.4336 13.6642V12.9043Z"
          fill="#E42D4F"
        />
        <path
          d="M6.48504 15.989C5.97618 15.989 5.48818 15.7443 5.12836 15.3087C4.76855 14.8731 4.56641 14.2822 4.56641 13.6662V12.9062H8.40367V13.6662C8.40367 14.2822 8.20153 14.8731 7.84172 15.3087C7.4819 15.7443 6.99389 15.989 6.48504 15.989Z"
          fill="#D1E1F0"
        />
        <path
          d="M10.3222 15.989C10.0701 15.9892 9.82052 15.9293 9.5876 15.8126C9.35468 15.696 9.14303 15.5249 8.96474 15.3092C8.78644 15.0935 8.64501 14.8373 8.54851 14.5554C8.45201 14.2735 8.40234 13.9713 8.40234 13.6662V12.9062H12.2408V13.6662C12.2408 14.2822 12.0387 14.8731 11.6789 15.3087C11.3191 15.7443 10.8311 15.989 10.3222 15.989Z"
          fill="#E42D4F"
        />
        <path
          d="M12.2422 12.9043H16.0807V13.6642C16.0807 13.9693 16.0311 14.2713 15.9346 14.5531C15.8382 14.835 15.6969 15.091 15.5187 15.3067C15.3406 15.5224 15.1291 15.6935 14.8963 15.8103C14.6635 15.927 14.414 15.9871 14.162 15.9871C13.91 15.9873 13.6604 15.9273 13.4274 15.8107C13.1945 15.694 12.9829 15.523 12.8046 15.3073C12.6263 15.0915 12.4848 14.8354 12.3884 14.5535C12.2919 14.2716 12.2422 13.9694 12.2422 13.6642V12.9043Z"
          fill="#D1E1F0"
        />
        <path
          d="M16.0781 12.9043H19.9166V13.6642C19.9166 14.2803 19.7145 14.8711 19.3547 15.3067C18.9948 15.7423 18.5068 15.9871 17.998 15.9871C17.7459 15.9873 17.4963 15.9273 17.2634 15.8107C17.0305 15.694 16.8188 15.523 16.6405 15.3073C16.4622 15.0915 16.3208 14.8354 16.2243 14.5535C16.1278 14.2716 16.0781 13.9694 16.0781 13.6642V12.9043Z"
          fill="#E42D4F"
        />
        <path
          d="M21.8378 15.989C21.5858 15.9892 21.3361 15.9293 21.1032 15.8126C20.8703 15.696 20.6587 15.5249 20.4804 15.3092C20.3021 15.0935 20.1606 14.8373 20.0641 14.5554C19.9676 14.2735 19.918 13.9713 19.918 13.6662V12.9062H23.7565V13.6662C23.7565 14.2822 23.5543 14.8731 23.1945 15.3087C22.8347 15.7443 22.3467 15.989 21.8378 15.989Z"
          fill="#D1E1F0"
        />
        <path
          d="M25.6738 15.989C25.4217 15.9892 25.1721 15.9293 24.9392 15.8126C24.7062 15.696 24.4946 15.5249 24.3163 15.3092C24.138 15.0935 23.9966 14.8373 23.9001 14.5554C23.8036 14.2735 23.7539 13.9713 23.7539 13.6662V12.9062H27.5924V13.6662C27.5924 14.2822 27.3903 14.8731 27.0304 15.3087C26.6706 15.7443 26.1826 15.989 25.6738 15.989Z"
          fill="#E42D4F"
        />
        <path
          d="M29.5136 15.989C29.2615 15.9892 29.0119 15.9293 28.779 15.8126C28.5461 15.696 28.3344 15.5249 28.1561 15.3092C27.9778 15.0935 27.8364 14.8373 27.7399 14.5554C27.6434 14.2735 27.5937 13.9713 27.5938 13.6662V12.9062H31.4322V13.6662C31.4322 14.2822 31.2301 14.8731 30.8703 15.3087C30.5105 15.7443 30.0225 15.989 29.5136 15.989Z"
          fill="#D1E1F0"
        />
        <path
          d="M12.4922 29.8183V18.2307C12.4922 17.6805 12.1237 17.2344 11.6692 17.2344H5.8605C5.40599 17.2344 5.03753 17.6805 5.03753 18.2307L5.03753 29.8183C5.03753 30.3686 5.40599 30.8147 5.8605 30.8147H11.6692C12.1237 30.8147 12.4922 30.3686 12.4922 29.8183Z"
          fill="#E7EFFF"
        />
        <path
          d="M11.6702 17.7207H5.86149C5.62948 17.7207 5.44141 17.9484 5.44141 18.2293V29.8154C5.44141 30.0963 5.62948 30.324 5.86149 30.324H11.6702C11.9022 30.324 12.0903 30.0963 12.0903 29.8154V18.2293C12.0903 17.9484 11.9022 17.7207 11.6702 17.7207Z"
          fill="#3B9BF4"
        />
        <path
          d="M8.20092 18.3301H6.37687C6.16657 18.3301 5.99609 18.5365 5.99609 18.7911V29.2127C5.99609 29.4674 6.16657 29.6738 6.37687 29.6738H8.20092C8.41122 29.6738 8.5817 29.4674 8.5817 29.2127V18.7911C8.5817 18.5365 8.41122 18.3301 8.20092 18.3301Z"
          fill="#3BB8FF"
        />
        <path
          d="M11.158 18.3301H9.3339C9.12361 18.3301 8.95312 18.5365 8.95312 18.7911V29.2127C8.95312 29.4674 9.12361 29.6738 9.3339 29.6738H11.158C11.3683 29.6738 11.5387 29.4674 11.5387 29.2127V18.7911C11.5387 18.5365 11.3683 18.3301 11.158 18.3301Z"
          fill="#3BB8FF"
        />
        <path
          d="M30.5234 26.4312V18.6075C30.5234 17.8847 30.0395 17.2988 29.4425 17.2988H16.6361C16.0391 17.2988 15.5552 17.8847 15.5552 18.6075V26.4312C15.5552 27.1539 16.0391 27.7398 16.6361 27.7398H29.4425C30.0395 27.7398 30.5234 27.1539 30.5234 26.4312Z"
          fill="#E7EFFF"
        />
        <path
          d="M29.4406 17.7656H16.6354C16.2521 17.7656 15.9414 18.1418 15.9414 18.6058V26.428C15.9414 26.8921 16.2521 27.2683 16.6354 27.2683H29.4406C29.8239 27.2683 30.1346 26.8921 30.1346 26.428V18.6058C30.1346 18.1418 29.8239 17.7656 29.4406 17.7656Z"
          fill="#3B9BF4"
        />
        <path d="M34.3616 32.2539H1.78906V33.5358H34.3616V32.2539Z" fill="#E7EFFF" />
        <path d="M35.2732 33.5352H0.882812V35.2721H35.2732V33.5352Z" fill="#D1E1F0" />
      </svg>
    </SvgIcon>
  )
}

export default ShopSidebarIcon
