import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import TypedChannel from 'interfaces/channel.interface'
import channelService, { detailChannel } from '../service'
import { ISetChannel, IUpdateChannel } from './channel.action'
import { IRootState } from 'config/store'
import { GAMIFA_PRIMARY_COLOR } from 'constant/index'

type ChannelState = {
  channel: TypedChannel
  loading: boolean
  error: string
}

const initialState: ChannelState = {
  channel: null,
  loading: false,
  error: null
}

export const fetchChannelInfo = createAsyncThunk('channel/getDetailChannel', async () => {
  const response = await detailChannel()
  return response.data
})

export const updateChannelThunk = createAsyncThunk(
  'channel/updateChannel',
  async (updatedChannel: Partial<TypedChannel> & Pick<TypedChannel, '_id'>) => {
    const response = await channelService.updateChannel(updatedChannel)
    return response.data
  }
)

const channelSlice = createSlice({
  initialState,
  name: 'channelSlice',
  reducers: {
    setChannel: (state, action: ISetChannel) => {
      state.channel = action.payload
    },
    updateChannel: (state, action: IUpdateChannel) => {
      return {
        ...state,
        channel: {
          ...state.channel,
          ...action.payload
        }
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchChannelInfo.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchChannelInfo.fulfilled, (state, action) => {
        state.channel = {
          ...action.payload,
          primary_color: action.payload.primary_color || GAMIFA_PRIMARY_COLOR
        }
        state.loading = false
      })
      .addCase(updateChannelThunk.fulfilled, (state, action) => {
        state.channel = action.payload
      })
  }
})

export const channelInfo = (state: IRootState) => state.channelSlice.channel
export const { updateChannel } = channelSlice.actions
export default channelSlice.reducer
