//Library
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

//Config
import { createEntitySlice, serializeAxiosError } from 'config/reducer.utils'

//Interface
import { TypeDataFollowCount, TypeDataUser } from '../interface'
import { TypedDataMember } from 'interfaces/member.interface'

//Helpers
import helpers from 'helpers'

interface TypeUpdateUser {
  _id: string
  user_cover?: string
  user_avatar?: string
  notification_chat?: string
  notification_community?: string
  notification_course?: string
  _language?: string
}

export const initialState = {}

export const getDataUser = createAsyncThunk(
  'detail_user/get_data',
  async (_id: string, { rejectWithValue }) => {
    try {
      return await axios.get<TypeDataUser>(`/user/detail/${_id}`)
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const uploadImage = createAsyncThunk(
  'detail_user/upload_image',
  async (body: unknown, { rejectWithValue }) => {
    try {
      const response = await axios.post<unknown>(
        `${process.env.REACT_APP_MEDIA_UPLOAD_URL}`,
        body,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const follow = createAsyncThunk(
  'detail_user/follow',
  async (body: { partner_id: string }, { rejectWithValue }) => {
    try {
      return await axios.post<unknown>(`/user/follow`, helpers.cleanEntity(body))
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const unFollow = createAsyncThunk(
  'detail_user/un_follow',
  async (body: { partner_id: string }, { rejectWithValue }) => {
    try {
      return await axios.post<unknown>(`/user/un-follow`, helpers.cleanEntity(body))
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const createChatroomToUser = createAsyncThunk(
  'detail_user/create_chatroom_to_user',
  async (body: { partner_id: string; chat_type: string | 'personal' }, { rejectWithValue }) => {
    try {
      return await axios.post<unknown>(`chat-room/create`, helpers.cleanEntity(body))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const DETAIL_USER_REDUCER = createEntitySlice({
  name: 'detail_user',
  initialState: initialState as any,
  reducers: {},
  extraReducers(builder) {}
})

export const {} = DETAIL_USER_REDUCER.actions

export default DETAIL_USER_REDUCER.reducer
