import { SvgIcon, SvgIconProps } from '@mui/material'

const InformationCircleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3359 3C7.36537 3 3.33594 7.02944 3.33594 12C3.33594 16.9706 7.36537 21 12.3359 21C17.3065 21 21.3359 16.9706 21.3359 12C21.3359 7.02944 17.3065 3 12.3359 3ZM1.33594 12C1.33594 5.92487 6.26081 1 12.3359 1C18.4111 1 23.3359 5.92487 23.3359 12C23.3359 18.0751 18.4111 23 12.3359 23C6.26081 23 1.33594 18.0751 1.33594 12ZM12.3359 6.99977C12.8882 6.99977 13.3359 7.44748 13.3359 7.99977V8.00977C13.3359 8.56205 12.8882 9.00977 12.3359 9.00977C11.7837 9.00977 11.3359 8.56205 11.3359 8.00977V7.99977C11.3359 7.44748 11.7837 6.99977 12.3359 6.99977ZM12.3359 10.0098C12.8882 10.0098 13.3359 10.4575 13.3359 11.0098V16.0098C13.3359 16.5621 12.8882 17.0098 12.3359 17.0098C11.7837 17.0098 11.3359 16.5621 11.3359 16.0098V11.0098C11.3359 10.4575 11.7837 10.0098 12.3359 10.0098Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default InformationCircleIcon
