import __ from 'languages/index'
import FlagEN from 'media/images/english.png'
import FlagVN from 'media/images/vietnam.png'

export const GAMIFA_PRIMARY_COLOR = localStorage.getItem('primary_color') || '#DC3545'

export enum EnumRole {
  Mentor = 'mentor',
  User = 'user',
  Admin = 'admin'
}

export enum EnumPointDataKey {
  Comment = 'comment',
  LikePost = 'like_post',
  ViewCourse = 'view_course',
  LikeComment = 'like_comment',
  PostNew = 'post_new',
  InviteUser = 'invite_user'
}

export const GAMIFA_CHANNEL_HOST = [
  'localhost:3000',
  'dev.gamifa.appuni.io',
  'social.gamifa.vn',
  'social.gamifa.com'
]

export const AUTH_SCREEN = ['/auth', '/login', '/register', '/validate-code', '/forgot-password']

export const CountryLanguage = [
  { id: '1', name: `${__('country_english')}`, flag: FlagEN },
  { id: '2', name: `${__('country_vietnam')}`, flag: FlagVN }
]

export const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128]
