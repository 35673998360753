import { useParams } from 'react-router-dom'

import Theme404 from '../../layout/404'

import ChannelSettingPage from './channel.setting'
import BigLayout from '../../layouts/bigLayout'

export default function ChannelSetting() {
  let useParam = {} as any
  useParam = useParams()
  let Param = useParam.slug || 'channel-setting'
  let ActualPage: any

  switch (Param) {
    case 'channel-setting':
      ActualPage = ChannelSettingPage
      break
    default:
      ActualPage = Theme404
      break
  }
  return (
    <BigLayout>
      <div className="mainFrame">
        <div className="">{<ActualPage />}</div>
      </div>
    </BigLayout>
  )
}
