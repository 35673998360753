import { SvgIcon, SvgIconProps } from '@mui/material'

const ArrowPlusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6641 1C21.2163 1 21.6641 1.44772 21.6641 2V5.9C21.6641 6.50752 21.1716 7 20.5641 7H16.6641C16.1118 7 15.6641 6.55228 15.6641 6C15.6641 5.44772 16.1118 5 16.6641 5H18.3221C14.7875 2.13593 9.58819 2.34795 6.3001 5.63604C4.54255 7.39359 3.66406 9.69515 3.66406 12C3.66406 12.5523 3.21635 13 2.66406 13C2.11178 13 1.66406 12.5523 1.66406 12C1.66406 9.1864 2.7382 6.36951 4.88589 4.22183C8.931 0.176716 15.3428 -0.0593159 19.6641 3.51373V2C19.6641 1.44772 20.1118 1 20.6641 1ZM12.6641 7C13.2163 7 13.6641 7.44772 13.6641 8V11H16.6641C17.2163 11 17.6641 11.4477 17.6641 12C17.6641 12.5523 17.2163 13 16.6641 13H13.6641V16C13.6641 16.5523 13.2163 17 12.6641 17C12.1118 17 11.6641 16.5523 11.6641 16V13H8.66406C8.11178 13 7.66406 12.5523 7.66406 12C7.66406 11.4477 8.11178 11 8.66406 11H11.6641V8C11.6641 7.44772 12.1118 7 12.6641 7ZM22.6641 11C23.2163 11 23.6641 11.4477 23.6641 12C23.6641 14.8136 22.5899 17.6305 20.4422 19.7782C16.3971 23.8233 9.98536 24.0593 5.66406 20.4863V22C5.66406 22.5523 5.21635 23 4.66406 23C4.11178 23 3.66406 22.5523 3.66406 22V18.1C3.66406 17.4925 4.15654 17 4.76406 17H8.66406C9.21635 17 9.66406 17.4477 9.66406 18C9.66406 18.5523 9.21635 19 8.66406 19H7.00605C10.5406 21.8641 15.7399 21.6521 19.028 18.364C20.7856 16.6064 21.6641 14.3048 21.6641 12C21.6641 11.4477 22.1118 11 22.6641 11Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default ArrowPlusIcon
