import { SvgIcon, SvgIconProps } from '@mui/material'

const BookmarkCheckIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 5.33398C4 3.12485 5.79086 1.33398 8 1.33398H24C26.2091 1.33398 28 3.12485 28 5.33398V20.0007C28 21.2597 27.4072 22.4452 26.4 23.2006L18.4 29.2006C16.9778 30.2673 15.0222 30.2673 13.6 29.2006L5.6 23.2007L6.39158 22.1452L5.6 23.2006C4.59278 22.4452 4 21.2597 4 20.0007V5.33398ZM8 4.00065C7.26362 4.00065 6.66667 4.5976 6.66667 5.33398V20.0007C6.66667 20.4203 6.86426 20.8155 7.2 21.0673L15.2 27.0673C15.6741 27.4229 16.3259 27.4229 16.8 27.0673L24.8 21.0673C25.1357 20.8155 25.3333 20.4203 25.3333 20.0007V5.33398C25.3333 4.5976 24.7364 4.00065 24 4.00065H8ZM20.9392 9.72093C21.4619 10.2396 21.4651 11.0839 20.9464 11.6065L15.7999 16.7923C15.0832 17.5145 13.9168 17.5145 13.2001 16.7923L11.0536 14.6295C10.5349 14.1068 10.5381 13.2626 11.0608 12.7438C11.5835 12.2251 12.4277 12.2283 12.9464 12.751L14.5 14.3165L19.0536 9.7281C19.5723 9.20543 20.4165 9.20221 20.9392 9.72093Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default BookmarkCheckIcon
